import React, { useEffect, useState } from "react";
// import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import ChildItem from "./ChildItem";
import MageButton from "../mageButton";
import scrollToBlock from "../../helper/scrollToBlock";
import * as constants from "../../helper/constants";
import Img from "gatsby-image";

const PreviewEventsItem = ({ startAnimation, eventsCategoryFields, eventsListItems, upcomingPreviewBlock }) => {
    const sanitizeOpt = sanitizeOptions();
    const { titleH2, count } = eventsCategoryFields;
    const { title, time, textButton, previewBackground, previewBackgroundMobile } = upcomingPreviewBlock;
    const [previewBackgroundImg, setPreviewBackgroundImg] = useState(previewBackground?.remoteFile?.childImageSharp?.fluid?.srcWebp);
    const scrollToChart = () => {
        scrollToBlock(`.form-block`);
    };
    useEffect(() => {
        if (window.innerWidth < constants.SM_MOBILE_WIDTH) {
            setPreviewBackgroundImg(previewBackgroundMobile?.remoteFile?.childImageSharp?.fluid?.srcWebp);
        }
    }, [previewBackgroundMobile]);

    return (
        <>
            <div className={'preview-wrapper'}
                 style={{ backgroundImage: `url(${previewBackgroundImg})` }}>
                <div className={'title-preview-block'}>
                    {!!title && <div className={'title-block'}>
                        <div className={'title'}
                             dangerouslySetInnerHTML={{ __html: sanitizeHtml(title, sanitizeOpt.contentData) }}/>
                        {!!count && <div className={'count-block'}>
                            {count}
                        </div>}
                    </div>}
                    <div className={'preview-items'}>
                        {Array.isArray(eventsListItems)
                        && eventsListItems.length
                        && eventsListItems.map((elements, i) => (
                            <ChildItem
                                key={i}
                                i={i}
                                elements={elements}
                                startAnimation={startAnimation}
                                previewContent={true}
                            />
                        ))}
                    </div>
                </div>
                <div className={'date-button-block'}>
                    {titleH2 && <div className={'date-block'}>
                        <div className={'date'}
                             dangerouslySetInnerHTML={{ __html: sanitizeHtml(titleH2, sanitizeOpt.contentData) }}/>
                        <div className={'time-wrapper'}
                             dangerouslySetInnerHTML={{ __html: sanitizeHtml(time, sanitizeOpt.contentData) }}
                        />
                    </div>}
                    {titleH2 &&
                    <MageButton
                        clickEvent={scrollToChart}
                        color={`#EC5C2B`}
                        size={125}
                        text={textButton}
                        textColor={`white`}/>
                    }
                </div>
            </div>
        </>
    )
}

const PreviewEvents = ({ isVisible, data, upcomingPreviewBlock, upcomingEventsState }) => {
    const [startAnimation, setStartAnimation] = useState(false);
    const { withoutUpcomingEventsImage } = upcomingPreviewBlock;
    useEffect(() => {
        // Disable animation for mobile
        // let setIsVisible = disableAnimationForMobile(isVisible);
        // if (setIsVisible) {
        //     setStartAnimation(true);
        // }
            setStartAnimation(true);
    }, [isVisible]);
    return (
        <div className={`events__preview-wrapper ${!upcomingEventsState ? 'without-upcoming-event' : ''}`}>
            {upcomingEventsState ?
                data?.filter(el => !!el?.eventsCategoryFields?.upcomingEvent)
                    .map((el, i) => (
                        <PreviewEventsItem
                            key={i}
                            eventsCategoryFields={el?.eventsCategoryFields}
                            eventsListItems={el?.events?.nodes}
                            startAnimation={startAnimation}
                            upcomingPreviewBlock={upcomingPreviewBlock}
                        />
                    )) :
                <>
                    {!!withoutUpcomingEventsImage?.remoteFile?.childImageSharp && <Img
                        style={{
                            transform: startAnimation,
                            transition: `all .75s ease .5s`
                        }}
                        fluid={
                            withoutUpcomingEventsImage.remoteFile.childImageSharp.fluid
                        }
                    />}
                </>}
        </div>
    );
}

export default PreviewEvents