import React from "react";
import ChildItem from "./ChildItem";
import Slider from 'react-slick';

const ChildList = ({ startAnimation, data }) => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 1000,
        autoplaySpeed: 3000,
        fadeIn: true,
        autoplay: false,
        pauseOnHover: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <>
            <div className={'child-list-wrapper'}>
                <Slider {...settings}>
                {Array.isArray(data) && data.length && data.map((elements, i) => (
                    <ChildItem
                        key={i}
                        i={i}
                        elements={elements}
                        startAnimation={startAnimation}
                    />
                ))}
                </Slider>
            </div>
        </>
    )
}

export default ChildList