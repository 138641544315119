import React, { useEffect, useState } from "react";
import TestimonialsBlock from "../testimonials";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import TitleBlock from "../title-lists/TitleBlock";

const TestimonialEvents = ({ isVisible, testimonialsItems, testimonial }) => {
    const carouselSettings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 1000,
        autoplaySpeed: 3000,
        fadeIn: true,
        autoplay: false,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const { titleH2, titleH5 } = testimonial;
    const [animateContent, setAnimateContent] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);

        if (setIsVisible) {
            window.setTimeout(() => {
                setAnimateContent(true);
            }, 200);
        }
    }, [isVisible]);

    return (
        <div className={'events-testimonials-wrapper'}>
            <div className={'left-container flex-container'}>
                <TitleBlock
                    titleH2={titleH2}
                    titleH5={titleH5}
                    isVisible={animateContent}
                />
            </div>
            <TestimonialsBlock
                testimonialsItems={testimonialsItems}
                carouselSettings={carouselSettings}
                isVisible={animateContent}/>
        </div>
    )
}

export default TestimonialEvents