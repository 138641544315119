import React from "react";
import ChildList from './ChildList';
import TrackVisibility from 'react-on-screen';
import TitleBlock from "../title-lists/TitleBlock";
import scrollToBlock from "../../helper/scrollToBlock";

const ParentItem = ({ i, startAnimation, events, eventsCategoryFields, description, button, upcomingEventsState }) => {
    const { upcomingEvent, titleH2, titleH5, count } = eventsCategoryFields;
    return (
        <>
            <div className={`parent-items ${(upcomingEventsState && upcomingEvent) ? 'upcoming-event' : ''}`}>
                {(i < 1) && (
                    <div
                        className={'separator-line top'}
                        style={{
                            width: startAnimation ? '100%' : '0',
                            transition: 'all .5s ease'
                        }}
                    />
                )}
                <div className={'left-container flex-container'}>
                    <TrackVisibility once offset={50}>
                        <TitleBlock
                            titleH2={titleH2}
                            titleH5={titleH5}
                        />
                    </TrackVisibility>
                </div>
                <div className={'right-container'}>
                    {upcomingEventsState && upcomingEvent ?
                        <>
                            <p className={'paragraph-primary__bold'}>{description}</p>
                            <button
                                onClick={() => {
                                    scrollToBlock(`.form-block`)
                                }}
                                className={'transition-link mc-link mc-button-secondary bottom'}>
                                <span className={'label'}>{button}</span>
                            </button>
                        </>
                        : <div className={'count h2-title'}>{count}</div>}
                </div>
                {Array.isArray(events?.nodes)
                && events?.nodes.length
                && <ChildList
                    data={events.nodes}
                    startAnimation={startAnimation}
                />}
                <div
                    className={'separator-line bottom'}
                    style={{
                        width: startAnimation ? '100%' : '0',
                        transition: 'all 1s ease'
                    }}
                />
            </div>
        </>
    )
}

export default ParentItem