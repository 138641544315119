import React from "react";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import VideoImageBlock from "../video-image-block";

const ChildItem = ({ i, startAnimation, elements, previewContent }) => {
    const { content, title, eventsListItems } = elements;
    const { name, photo, position, photoPreview } = eventsListItems;
    const sanitizeOpt = sanitizeOptions();
    const imageSourcePreview = photoPreview;
    const imageSource = previewContent ? imageSourcePreview : photo?.imageSource;
    const link = !previewContent ? photo.link : false;
    return (
        <>
            <div className={'child-items'}>
                {(!!imageSource || !!imageSourcePreview)
                && <VideoImageBlock
                    isVisible={true}
                    data={{ link, imageSource }}
                />}
                <div className={'h4-title-wrapper'}>
                    {!!name && <div
                        style={{
                            opacity: `${startAnimation ? 1 : 0}`,
                            transition: 'all .75s ease .7s'
                        }}
                        className={'name h4-title'}>
                        {name}
                    </div>}
                    {!!position && <div
                        style={{
                            opacity: `${startAnimation ? 1 : 0}`,
                            transition: 'all .75s ease .7s'
                        }}
                        className={'position'}>
                        {position}
                        <div className={'border-bottom'}
                             style={{
                                 width: `${startAnimation ? '100%' : 0}`,
                                 transition: 'all .75s ease .5s'
                             }}
                        />
                    </div>}
                    {previewContent ?
                        !!title && <div
                            className={'text'}
                            style={{
                                opacity: `${startAnimation ? 1 : 0}`,
                                transition: 'all .75s ease .7s'
                            }}
                        >
                            <h3 dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(title, sanitizeOpt.contentData)
                            }}/>
                        </div> :
                        !!content && <div
                            className={'text'}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(content, sanitizeOpt.contentData)
                            }}
                            style={{
                                opacity: `${startAnimation ? 1 : 0}`,
                                transition: 'all .75s ease .7s'
                            }}
                        />}
                </div>
            </div>
        </>
    )
}

export default ChildItem