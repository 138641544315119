import React, { useEffect, useState } from "react";
// import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import ParentList from "./ParentList";

const Events = ({ isVisible, eventsList, eventsListParent, button, upcomingEventsState, id }) => {
    const sanitizeOpt = sanitizeOptions();
    const { title, description } = eventsList;
    const [startAnimation, setStartAnimation] = useState(false);
    useEffect(() => {
        // Disable animation for mobile
        // let setIsVisible = disableAnimationForMobile(isVisible);
        // if (setIsVisible) {
        //     setStartAnimation(true);
        // }
        setStartAnimation(true);
    }, [isVisible]);
    return (
        <div className={'events-list-wrapper'}
        id={!!id ? id : ''}>
            <div className={'left-container'}>
                {!!title && (
                    <h2
                        className={'h2-title'}
                        style={{ overflow: 'hidden' }}
                    >
                        <div className={'text'}
                             style={{
                                 transform: `translateY(${startAnimation ? '0' : '100%'})`,
                                 transition: `all .75s ease .1s`
                             }}
                             dangerouslySetInnerHTML={{ __html: sanitizeHtml(title, sanitizeOpt.contentData) }}/>
                    </h2>
                )}
            </div>
            <ParentList
                data={eventsListParent}
                startAnimation={startAnimation}
                description={description}
                button={button}
                upcomingEventsState={upcomingEventsState}
            />
        </div>
    )
}

export default Events