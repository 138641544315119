import React from "react";
import ParentItem from "./ParentItem";

const ParentList = ({ startAnimation, data, description, button, upcomingEventsState }) => {
    return (
        <>
            <div className={'parent-list-wrapper'}>
                {Array.isArray(data) && data.length && data.map((elements, i) => (
                    <ParentItem
                        key={i}
                        i={i}
                        events={elements?.events}
                        eventsCategoryFields={elements?.eventsCategoryFields}
                        startAnimation={startAnimation}
                        description={description}
                        button={button}
                        upcomingEventsState={upcomingEventsState}
                    />
                ))}
            </div>
        </>
    )
}

export default ParentList