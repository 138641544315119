import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import Layout from '../components/layout';
import TitleLarge from "../components/title-large-content";
import WhoWeAre from "../components/careers-who-we-are";
// import OurEvents from "../components/careers-our-events";
import scrollToBlock from "../helper/scrollToBlock";
import { Helmet } from "react-helmet";
import TrackVisibility from 'react-on-screen';
import Events from '../components/events';
import PreviewEvents from "../components/events/PreviewEvents";
import TestimonialEvents from "../components/events/TestimonialEvents";
import useUpcomingEvents from "../hooks/useUpcomingEvents";
import prevNextPaginationButtons from "../helper/prevNextPaginationButtons";
import ReactPaginate from "react-paginate";

export default ({
                    data: {
                        eventsCategoryList,
                        events: {
                            title,
                            eventsCustomFields: {
                                eventsTitleLargeContent,
                                upcomingPreviewBlock,
                                endTimeUpcomingEvent,
                                eventsWhoWeAre,
                                // eventsTakingPart,
                                eventsList,
                                eventsContactUsBlock,
                                testimonial,
                            },
                            seo, additionalMetaTag
                        }, testimonials
                    }, pageContext
                }) => {
    const upcomingEventsState = useUpcomingEvents(eventsCategoryList?.nodes, endTimeUpcomingEvent);
    const whoWeAre = eventsWhoWeAre;
    const dataContactBlock = { ...eventsContactUsBlock, upcomingEventsState };
    const socialLinks = whoWeAre?.socialLinks;
    const eventsListWrapper = 'events-list';
    // Set metadata
    const seoData = { ...{ seo }, additionalMetaTag, title };
    let eventsArray = Array.isArray(testimonials?.nodes) && testimonials?.nodes.filter((el) => (!!el?.testimonialsCustomFields?.events));
    //Pagination
    const prevButton = `${process.env.KEY_PAGINATION_EVENTS}-previous`;
    const nextButton = `${process.env.KEY_PAGINATION_EVENTS}-next`;
    const keyPagination = process.env.KEY_PAGINATION_EVENTS;
    const [paginateButtons, setPaginateButtons] = useState(false);
    useEffect(() => {
        const prevButtonEl = document.getElementsByClassName(prevButton);
        const nextButtonEl = document.getElementsByClassName(nextButton);
        const paginateButtonsEl = { prevButtonEl, nextButtonEl };
        setPaginateButtons(paginateButtonsEl);
    }, [prevButton, nextButton]);
    return (
        <Layout
            seoData={seoData}>
            <div className={'events inner-pages'}
                 datafld={{ dataContactBlock }}>
                <Helmet
                    bodyAttributes={{
                        class: 'events-main-page-wrapper'
                    }}
                />
                <div className={'events__title-block'}>
                    <TitleLarge
                        data={eventsTitleLargeContent}/>
                    {!!eventsTitleLargeContent.button && (
                        <button
                            onClick={() => {
                                scrollToBlock(`.${eventsListWrapper}-wrapper`)
                            }}
                            className={'transition-link mc-link mc-button-secondary bottom'}>
                        <span className={'label'}>
                            {eventsTitleLargeContent.button}
                        </span>
                        </button>
                    )}
                </div>
                <TrackVisibility once offset={250}>
                    {Array.isArray(eventsCategoryList?.nodes)
                    && eventsCategoryList?.nodes.length
                    && <PreviewEvents
                        data={eventsCategoryList.nodes}
                        upcomingPreviewBlock={upcomingPreviewBlock}
                        upcomingEventsState={upcomingEventsState}
                    />
                    }
                </TrackVisibility>
                <TrackVisibility once offset={250}>
                    <WhoWeAre data={{ whoWeAre, socialLinks }}/>
                </TrackVisibility>
                {/*<TrackVisibility once offset={350}>*/}
                {/*<OurEvents data={eventsTakingPart}/>*/}
                {/*</TrackVisibility>*/}
                <TrackVisibility once offset={250}>
                    <TestimonialEvents
                        testimonialsItems={eventsArray}
                        testimonial={testimonial}/>
                </TrackVisibility>
                <TrackVisibility once offset={500}>
                    {Array.isArray(eventsCategoryList?.nodes)
                    && eventsCategoryList?.nodes.length
                    && <Events
                        eventsList={eventsList}
                        eventsListParent={eventsCategoryList.nodes}
                        button={!!upcomingPreviewBlock?.textButton && upcomingPreviewBlock.textButton}
                        upcomingEventsState={upcomingEventsState}
                        id={process.env.KEY_PAGINATION_EVENTS}
                    />}
                </TrackVisibility>

                {pageContext && pageContext.totalPages > 1 && (
                    <div className={'page-content'}>
                        <div className={'pagination-wrapper'}>
                            <ReactPaginate
                                previousClassName={prevButton}
                                nextClassName={nextButton}
                                previousLabel={
                                    pageContext?.page !== 1 && <span className={'pager-arrow arrow-left'}/>
                                }
                                nextLabel={
                                    pageContext?.totalPages !== pageContext.page && (
                                        <span className={'pager-arrow arrow-right'}/>
                                    )
                                }
                                onPageChange={({ selected }) => {
                                    const page = selected + 1
                                    const path = page === 1 ? `/${keyPagination}/#${process.env.KEY_PAGINATION_EVENTS}` : `/${keyPagination}/${page}/#${process.env.KEY_PAGINATION_EVENTS}`
                                    navigate(path)
                                }}
                                disableInitialCallback
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageContext.totalPages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                containerClassName={"pager blog-pager flex-justify-sb pager-list flex-container"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                initialPage={pageContext.page - 1}
                                pageClassName={"pager-item flex-center"}
                                hrefBuilder={(page) => {
                                    prevNextPaginationButtons(paginateButtons);
                                    return `/${keyPagination}/${page}/#${process.env.KEY_PAGINATION_EVENTS}`;
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export const query = graphql`
    query EventsQuery($offset: Int!, $perPage: Int!) {
        eventsCategoryList: allWpEventsCategory(
            limit: $perPage
            skip: $offset
        ) {
            nodes {
                eventsCategoryFields {
                    count
                    fieldGroupName
                    titleH2
                    titleH5
                    upcomingEvent
                }
                events {
                    nodes {
                        title
                        content
                        eventsListItems {
                            name
                            position
                            photo {
                                link
                                imageSource {
                                    remoteFile {
                                        childImageSharp {
                                            fluid(quality: 100) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            }
                            photoPreview {
                                remoteFile {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        events: wpPage(slug: {eq: "events"}) {
            title
            eventsCustomFields {
                eventsTitleLargeContent {
                    button
                    title
                }
                upcomingPreviewBlock {
                    title
                    time
                    textButton
                    previewBackground {
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                    srcWebp
                                }
                            }
                        }
                    }
                    previewBackgroundMobile {
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                    srcWebp
                                }
                            }
                        }
                    }
                    withoutUpcomingEventsImage {
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                endTimeUpcomingEvent
                eventsWhoWeAre {
                    amountBlock {
                        fieldGroupName
                        title
                        text
                    }
                    descriptionBlock {
                        title
                        text
                    }
                    socialLinks
                }
                eventsTakingPart {
                    title
                    text
                    button {
                        text
                        link
                    }
                    image {
                        link
                        imageSource {
                            remoteFile {
                                childImageSharp {
                                    fluid(quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
                eventsList {
                    description
                    title
                    eventsListParent {
                        titleH2
                        titleH5
                        upcomingEvent
                        count
                    }
                }
                eventsContactUsBlock {
                    description
                    text
                    titleH2
                    titleH4
                    classWrapper
                }
                testimonial {
                    titleH2
                    titleH5
                }
            }
            seo {
                title
                metaDesc
                canonical
            }
            additionalMetaTag {
                keywords
                ogDescription
                ogTitle
                ogType
                ogImage {
                    mediaItemUrl
                }
                twitterImage {
                    mediaItemUrl
                }
                twitterTitle
                twitterDescription
            }
        }

        testimonials: allWpTestimonial(
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                slug
                title
                id
                testimonials {
                    authorName
                    authorPosition
                    testimonial
                    image {
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                testimonialsCustomFields {
                    events {
                        ... on WpEvent {
                            id
                            uri
                        }
                    }
                }
                contentType {
                    node {
                        graphqlSingleName
                    }
                }
            }
        }
    }`