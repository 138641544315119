import {useState, useEffect} from 'react';

export default function useUpcomingEvents(eventsCategoryList, endTimeUpcomingEvent) {
    const [upcomingEventState, setUpcomingEventState] = useState(false);
    useEffect(() => {
        const convertedTime = () => {
            if (!!endTimeUpcomingEvent) {
                let time_parts = endTimeUpcomingEvent.split(":");
                return (time_parts[0] * (60000 * 60)) + (time_parts[1] * 60000);
            }
            return 0;
        };
        Array.isArray(eventsCategoryList) &&
        eventsCategoryList.length &&
        eventsCategoryList.forEach((el) => {
           if (el?.eventsCategoryFields?.upcomingEvent &&
               (Date.now() <= (Date.parse(el.eventsCategoryFields.titleH2) +
                   convertedTime()))) {
               setUpcomingEventState(true);
           }
        });
    }, [eventsCategoryList, endTimeUpcomingEvent]);
    return upcomingEventState;
}